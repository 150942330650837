import React from "react"
import styled from "styled-components"

import { HSection, Grid, ValueProp } from "components/common"

import { device, featureColors } from "utils"

const ProfitabilityTrueCost = ({ hat, title, subtitle, media, valueProps }) => {
  const renderGrid = () => (
    <Grid
      col={1}
      gap={24}
      align="center"
      width="100%"
      list={valueProps}
      component={(vp) => <ValueProp orientation="horizontal" {...vp} />}
    />
  )

  return (
    <StyledProfitabilityTrueCost>
      <HSection
        hat={hat}
        title={title}
        titleAs="h2"
        subtitle={subtitle}
        media={media}
        grid={renderGrid}
        mobileFull
      />
    </StyledProfitabilityTrueCost>
  )
}

const StyledProfitabilityTrueCost = styled.div`
  --tg-hat-color: ${featureColors.profitability};
  --section-max-width: 1080px;

  @media ${device.laptop} {
    --tg-max-width: 475px;
  }

  .vp_contentWrap {
    @media ${device.laptop} {
      --tg-max-width: 300px;
      grid-template-columns: 22px 1fr;
    }
    .image {
      padding-top: 3px;
    }
    .subtitle {
      font-weight: normal;
      line-height: 32px;
    }
  }
`

export default ProfitabilityTrueCost
