import React from "react"
import styled from "styled-components"

import { VerticalSection } from "components/common"

import { featureColors, featureGlass } from "utils"

const ProfitabilityReports = ({ hat, title, subtitle, media }) => {
  return (
    <StyledProfitabilityReports>
      <VerticalSection
        hat={hat}
        titleAs="h2"
        title={title}
        subtitle={subtitle}
        media={media}
        mobileTgOrder="1"
        mobileFull
      />
    </StyledProfitabilityReports>
  )
}

const StyledProfitabilityReports = styled.div`
  --tg-hat-color: ${featureColors.profitability};

  ${featureGlass};
  /* --tg-max-width: 743px; */
`

export default ProfitabilityReports
