import React from "react"
import styled from "styled-components"

import { VerticalSection } from "components/common"

import { featureColors } from "utils"

const ProfitabilitySalesMenu = ({ hat, title, subtitle, media }) => {
  return (
    <StyledProfitabilitySalesMenu>
      <VerticalSection
        hat={hat}
        titleAs="h2"
        title={title}
        subtitle={subtitle}
        media={media}
        mobileTgOrder="1"
        mobileFull
      />
    </StyledProfitabilitySalesMenu>
  )
}

const StyledProfitabilitySalesMenu = styled.div`
  --tg-hat-color: ${featureColors.profitability};
  --tg-max-width: 715px;
`

export default ProfitabilitySalesMenu
