import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, Nav } from "components/common"
import { useContentfulSection, useMeta } from "utils"

import {
  ProfitabilityHero,
  ProfitabilityTrackWhatMatters,
  ProfitabilityTrueCost,
  ProfitabilityReports,
  ProfitabilitySalesMenu,
} from "src/sections/features/profitability"

import ExploreFeatures from "src/sections/features/ExploreFeatures"
import { CtaGetDemo } from "components/cta"

const ProfitabilityPage = ({ data: { profitability } }) => {
  const {
    profitability_hero,
    profitability_track_what_matters,
    profitability_true_cost,
    profitability_reports,
    profitability_sales_menu,
    explore_more_features,
    cta_get_demo,
  } = useContentfulSection(profitability.sections)

  const meta = useMeta(profitability)

  return (
    <Layout>
      <Seo {...meta} />
      <Wrap>
        <Nav />
        <ProfitabilityHero {...profitability_hero} />
        <ProfitabilityTrackWhatMatters {...profitability_track_what_matters} />
        <ProfitabilityTrueCost {...profitability_true_cost} />
        <ProfitabilityReports {...profitability_reports} />
        <ProfitabilitySalesMenu {...profitability_sales_menu} />

        <ExploreFeatures {...explore_more_features} />
        <CtaGetDemo {...cta_get_demo} />
      </Wrap>
    </Layout>
  )
}

const Wrap = styled.div``

export const query = graphql`
  {
    profitability: contentfulPage(pageId: { eq: "profitability" }) {
      ...Page
    }
  }
`

export default ProfitabilityPage
